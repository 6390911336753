'use client'
import React from 'react';
import cl from './MainWrap.module.css'; 
import { MainDiv } from './ui';

import cn from 'classnames';

const MainWrap = ({ headerTitle, children, className, ...props }) => {
    return (
        <MainDiv className={cn(className)} {...props}>
            {children}
        </MainDiv>
    );
};

export default MainWrap;