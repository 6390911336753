import styled from "styled-components";

export const MainDiv = styled.div`
    .mainWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* padding: 0 20px 0; */
    padding: 66px 0px 0;
    /* gap: 20px; */
    /* border-radius: 20px; */
    /* margin-top: 46px; */
  }
`