'use client'
import React from 'react';
// import {Link} from 'react-router-dom';
import Link from 'next/link';
import cl from './CourseAvatar.module.css'
import Image from 'next/image'

const CourseAvatar = (props) => {
    return (
        <Link href={props.to ? props.to : props.href ? props.herf : null} className={cl.course_Avatar} {...props}>
            <img src={props.src} />
        </Link>
    );
};

export default CourseAvatar;