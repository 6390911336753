'use client'
import { getUserData, newLogIn, refreshToken, logout, api } from "@src/app/api"
import { EmptyAvatar } from "../UI/Fillers"
import { makeAutoObservable, makeObservable } from "mobx"
import { host } from "@src/app/globals"
import Cookies from "universal-cookie"
// import { cookies } from "next/headers"
// import { useRouter } from "next/router"


class User {
    auth = false
    authInProgress = true
    online = false
    data = {
        id: '',
        mail: '',
        role_id: '',
        user_photo: null,
        user_nikname: '',
        user_name: '',
        user_surname: '',
        user_patronymic: '',
        user_gmt_time: '',
        user_photo_ID: '',
        user_sex: ''
    }

    constructor() {
        makeObservable(this, {}, { autoBind: true })
    }

    async getStoreUserData(result) {
        const user = await getUserData();
        this.changeUser(user.data);
    }
    async changeUser(result) {
        const cookies = new Cookies()
        this.data = { ...this.data, ...result }
        if (!cookies.get('userId')) {
            this.setLocalStorageItem('userId', result.id)
        }
    }

    changeJwt(jwt) {
        this.setLocalStorageItem('token', jwt)
    }

    handlerStatus(message) {
        if (message.online) {
            this.online = true
        } else {
            this.online = false
        }
    }

    signIn() {
        this.auth = true
        this.authInProgress = false
    }

    signOut() {
        // const useRouter = useRouter()
        this.data = {}
        this.auth = false
        // return(router.push('/login'))
    }

    setLocalStorageItem(key, value) {
        const cookies = new Cookies()
        return new Promise((resolve) => {
            localStorage.setItem(key, value);
            cookies.set(key, value, { path: '/' })
            resolve();
        });
    }

    getUserPhoto(user) {
        const cookies = new Cookies()
        fetch(`${host}/api/GetFile/${user.data.user_photo_ID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookies.get('token')
            },
        })
            .then(response => response.status === 200 && response.blob(response))
            .then(blob => {
                if (blob) {
                    const fileURL = URL.createObjectURL(blob)
                    this.changeUser({ user_photo: fileURL })
                }
                else {
                    this.changeUser({ user_photo: EmptyAvatar.src })
                }
            })
            .catch((error) => {
                //  if (error.response.status === 404) {
                this.changeUser({ user_photo: EmptyAvatar.src })
                // } 
            })
    }

    async logIn(fields) {
        const cookies = new Cookies()
        try {
            const result = await newLogIn(fields);
            cookies.remove('token');
            cookies.set('token', result.data.token, { path: '/' })
            const user = await getUserData();
            this.changeUser(user.data)
            cookies.remove('role_id');
            cookies.set('role_id', user.data.role_id, { path: '/' })
            cookies.remove('user_photo_ID');
            cookies.set('user_photo_ID', user.data.user_photo_ID, { path: '/' })
            this.getUserPhoto(user)
            this.signIn()
        } catch (error) {
            throw error
        }
    }

    async checkAuth(token) {
        const cookies = new Cookies()
        this.authInProgress = true
        try {
            if (token !== 'undefined' || cookies.get('token')) {
                const user = await getUserData();
                this.changeUser(user.data);
                this.getUserPhoto(user);
                this.signIn()

                return (true)
            } else {
                this.authInProgress = false
                return (false)
            }
        } catch (error) {
            localStorage.clear()
            this.signOut()
            this.authInProgress = false
            return (false)
        }
    }

   

    async logOut() {

    //    const  deleteAllCookies = () =>  {
    //         var cookies = document.cookie.split(";");
        
    //         for (var i = 0; i < cookies.length; i++) {
    //             var cookie = cookies[i];
    //             var eqPos = cookie.indexOf("=");
    //             var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //             document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    //         }
    //     }

        const cookies = new Cookies()
        this.isAuthInProgress = true;
        try {
            await logout()
                .then(() => { localStorage.clear()})
            // localStorage.clear();
            this.signOut()
        } catch (err) {
        } finally {
            cookies.set('token', null, {maxAge: -1})
            cookies.set('userId', null, {maxAge: -1})
            cookies.set('role_id', null, {maxAge: -1})
            cookies.set('user_photo_ID', null, {maxAge: -1})
            // cookies.set('token', cookies.get('token').value, {maxAge: -1})
            // cookies.set('userId', cookies.get('userId').value, {maxAge: -1})
            // cookies.set('role_id', cookies.get('role_id').value, {maxAge: -1})
            // cookies.set('user_photo_ID', cookies.get('user_photo_ID').value, {maxAge: -1})

            cookies.remove('token', { path: '/' });
            cookies.remove('userId', { path: '/' });
            cookies.remove('role_id', { path: '/' });
            cookies.remove('user_photo_ID', { path: '/' });
            this.authInProgress = false;
            
        }
        return (true)
    }
}

export default new User()