"use client"
import React, { useState } from 'react';
import cl from './SimpleAvatar.module.css'
import cn from 'classnames'
import Image from 'next/image'


const SimpleAvatar = ({ photo, size = '50px', status, className }) => {


    return (
        <div className={cn(cl.dialogAvatar, className)}>
            <div className={cl.dialogPhoto}>
                <img src={photo} width={size} height={size} />
            </div>
            {status ?
                <div className={cl.statusUserContainer}>
                    <div className={cn(cl.statusUser, {
                        [cl.online]: status,
                        [cl.offline]: !status
                    })
                    }>
                    </div>
                </div>
                : null}
        </div>
    );
};

export default SimpleAvatar;

