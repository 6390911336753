"use client"
import React, { useEffect, useState } from 'react';
import cl from './HeaderMain.module.css'
import { observer } from 'mobx-react-lite';
import userStore from '@shared/store/userStore';
import { SlArrowDown } from "react-icons/sl";
import { FaChalkboardTeacher, FaThList } from "react-icons/fa";
import { ButtonDropDown } from '@shared/UI/Buttons';
import { FaSortDown } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { AccountAvatar } from '@ui/Avatars';
import { IoMdExit } from 'react-icons/io';
import Link from 'next/link';
import { useRouter, usePathname } from 'next/navigation'
import TotLogo from '@public/images/logo.svg'
import { AccountAvatarWrap, AccontMenu, DropNavLink, HeaderWrapStyled, HeaderlogoSection, Headerlogo, HeaderNavLink } from './HeaderMainStyled';
import { host } from '@src/app/globals';
import { ProfileDropDown } from './ui';
import { EmptyAvatar } from '@ui/Fillers';
import Cookies from 'universal-cookie';

const HeaderMain = ({ serverToken, innerUserData, serverRoleId, serverUserPhoto, serveridAvatar, serverAuth, ...props }) => {

    const cookies = new Cookies()

    const [visibleModal, setVisibleModal] = useState(false)
    const pathname = usePathname()
    const router = useRouter()
    const [role, setRole] = useState(() => { return serverRoleId })
    const [isAuth, setIsAuth] = useState(false)
    const [userPhoto, setUserPhoto] = useState(() => { return serverUserPhoto })
    const [userPhotoId, setUserPhotoId] = useState(() => { return serveridAvatar })
    const cookieAvatarId = cookies.get("user_photo_ID")
    const [headerUesrData, setHeaderUserData] = useState(() => { return innerUserData })

    useEffect(() => {
        if (serverToken !== 'undefined' && serverToken !== undefined) {
            const CheckAuthorisation = async () => {
                setIsAuth(await userStore.checkAuth(serverToken))
            }
            CheckAuthorisation()
        }

    }, []);

    useEffect(() => {
        // if (headerUesrData !== null) {
        //     setIsAuth(true)
        // }
        setUserPhoto(userStore?.data?.user_photo ?? userStore?.data?.user_photo?.src ?? EmptyAvatar.src)
    }, [userStore?.data]);


    const FullLogout = async () => {
        await userStore.logOut()
            .finally(() =>
                pathname === '/about' ?
                    router.refresh()
                    :
                    router.push('/about'))
    }
    
    return (
        <>
            <HeaderWrapStyled >
                <HeaderlogoSection>
                    <Headerlogo href="/">
                        <img src={TotLogo.src} alt='logo' />
                    </Headerlogo>
                </HeaderlogoSection>
                <HeaderNavLink
                    href={'/allcourses'}
                    $active={pathname === '/allcourses'}
                    state={{ showForm: 'auth' }}
                    className={cl.navLink} >
                    Список курсов
                </HeaderNavLink>
                {
                    (isAuth === true) ?
                        //Если пользователь авторизованн
                        <>
                            <HeaderNavLink
                                href={'/about'}
                                $active={pathname === '/about'}
                                state={{ showForm: 'auth' }}
                                className={cl.navLink} >
                                О нас
                            </HeaderNavLink>
                            {userStore?.data?.role_id == 2 ?
                                <ButtonDropDown
                                    className={cl.DropDownElement}
                                    BtnClass={cl.DropDownBtn}
                                    DropDownClass={cl.DropDownList}
                                    title={'Кабинет автора'}
                                    BtnText={<><FaChalkboardTeacher /> <p>Кабинет автора</p> <SlArrowDown /></>}
                                >
                                    <Link className={cl.DropNavLink} href={'/teachercabinete/course/list'} ><FaThList />Управление курсами</Link>
                                </ButtonDropDown>
                                : null}
                            <AccontMenu onClick={() => setVisibleModal(!visibleModal)}
                            >
                                <section style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                    <ButtonDropDown
                                        className={cl.DropDownElement}
                                        BtnClass={cl.DropDownBtn}
                                        DropDownClass={cl.DropDownList}
                                        title={'Моё обучение'}
                                        BtnText={<><p>Моё обучение</p> <SlArrowDown /></>}
                                    >
                                        <Link className={cl.DropNavLink} href={'/mylearn/mycourses'}><FaThList />Мои курсы </Link>
                                    </ButtonDropDown>
                                </section>
                                <AccountAvatarWrap>
                                    <>
                                        <AccountAvatar
                                            userAvatarSrc={userPhoto ?? userPhoto.src ?? EmptyAvatar.src}
                                            userPhotoId={userPhotoId}
                                            size={'46px'}
                                            photoId={userStore.data?.user_photo_ID ?? headerUesrData?.user_photo_ID}
                                        />
                                    </>
                                    <FaSortDown />
                                </AccountAvatarWrap>
                                <ProfileDropDown
                                    visible={visibleModal}
                                    setVisible={() => setVisibleModal(false)}
                                >
                                    <DropNavLink as={Link} className={cl.DropNavLink} href={'/profile/main'}>
                                        <FaThList />Профиль
                                    </DropNavLink>
                                    {
                                        userStore.data.role_id !== 2 &&
                                        <DropNavLink as={Link}
                                            className={cl.DropNavLink}
                                            href='/regteacher'>
                                            <FaUser />
                                            Стать автором
                                        </DropNavLink>
                                    }
                                    <DropNavLink
                                        onClick={() => FullLogout()}
                                        className={cl.DropNavLink}
                                    >
                                        <IoMdExit />
                                        Выйти
                                    </DropNavLink>
                                </ProfileDropDown>
                            </AccontMenu>
                        </>
                        :
                        <>
                            <HeaderNavLink
                                //  onClick={() => router.push('/login')}
                                href={'/login'}
                                $active={pathname === '/login'}
                                state={{ showForm: 'auth' }}
                                className={cl.navLink} >
                                Войти
                            </HeaderNavLink>
                            <HeaderNavLink href={'/registration'}
                                $active={pathname === '/registration'}
                                state={{ showForm: 'reg' }}
                                className={cl.navLink} >
                                Регистрация
                            </HeaderNavLink>
                        </>
                }
            </HeaderWrapStyled>
        </>
    );
};
export default observer(HeaderMain);