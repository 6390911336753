'use client'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cl from './AcountAvatar.module.css';
import { observer } from 'mobx-react-lite';
import userStore from '@shared/store/userStore';
import Image from 'next/image'
import { host } from '@src/app/globals';
import { EmptyAvatar, EmptyFile } from '@ui/Fillers';
import Cookies from 'universal-cookie';
import { api } from '@src/app/api';

//TODO запросить фотку пользователя при первом запросе, закешировать в паvяти


const AccountAvatar = ({ size = '50px', userAvatarSrc, serverPhotoId }) => {
    return (      
            <div
                className={cl.account_Avatar}
                style={{ width: size, minWidth: size, height: size, minHeight: size }}
            >
                <img src={userAvatarSrc === null ? EmptyAvatar.src : userAvatarSrc} />
            </div>   
    )
};
export default observer(AccountAvatar);