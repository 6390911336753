import axios from "axios";
import { hostApi, hostNext } from "../globals";
import Cookies from "universal-cookie";
import userStore from "@shared/store/userStore";
// import { useRouter } from "next/navigation";

const cookies = new Cookies()
// export const router = new useRouter()

export const api = axios.create({
  baseURL: `${hostApi}/api` //typeof window !== 'undefined' ?  `${hostNext}/api` : `${hostApi}/api` // `${hostApi}/api`,
})
api.interceptors.request.use(
  (config) => {
    // if (config.fromServer) {
    //   console.log(config.url, config.baseURL)
    //   // config.url.includes(`${hostNext}/api`)
    //   //   ? config.url = config.url
    //   //   : config.url = `${hostNext}/api${config.url}`
    // }
    if (typeof window !== 'undefined' && cookies.get('token') !== undefined) {
      config.headers.Authorization = `Bearer ${cookies.get('token')}`
    } else {
      config.headers.Authorization = config.headers.Authorization ? config.headers.Authorization : cookies.get('token') !== undefined ? `Bearer ${cookies.get('token')}` : config.token
    }
    
    return config
  }
)
api.interceptors.response.use(
  (config) => {
    return config
  },

  async (error) => {
    if (error.response?.status === 423) {
      cookies.remove('token', { path: '/' });
      cookies.remove('userId', { path: '/' });
      cookies.remove('role_id', { path: '/' });
      cookies.remove('user_photo_ID', { path: '/' });

      // router.push('/login?isbanned')
    }
    const originalRequest = { ...error.config, _isRetry: true };
    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      try {
        const response = await api.get('/user/refreshjwt', { fromServer: typeof window === 'undefined' });
        cookies.set('token', response.data.token, { path: '/' });
        return api.request(originalRequest);
      } 
      catch (error) {
        if (typeof window !== 'undefined') {
          //на  клиенте
          cookies.set('token', null, {maxAge: -1})
            cookies.set('userId', null, {maxAge: -1})
            cookies.set('role_id', null, {maxAge: -1})
            cookies.set('user_photo_ID', null, {maxAge: -1})

          cookies.remove('token', { path: '/' });
          cookies.remove('userId', { path: '/' });
          cookies.remove('role_id', { path: '/' });
          cookies.remove('user_photo_ID', { path: '/' });
          userStore.logOut() 
        } else { 
          //на сервере

          
        }
      }
    }
    throw error;
  }
);