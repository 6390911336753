import { api } from "./api.config";

export const newLogIn = (fields) => {
    return api.post(`/user/login`, fields, { cancel: true })
}

export const refreshToken = () => {
    return api.get("/user/refreshjwt" , {fromServer: typeof window === 'undefined'});
}

export const logout = () => {
    return api.get("/user/logout" , {fromServer: typeof window === 'undefined'})
}

export const getUserData = () => {
    return api.get('/user/0' , {fromServer: typeof window === 'undefined'})
}
