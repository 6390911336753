import {
    newLogIn,
    refreshToken,
    logout,
    getUserData
} from './api.auth'

import { api } from './api.config'

export {
    newLogIn,
    refreshToken,
    logout,
    getUserData,
    api
}