import styled from "styled-components";
import { ModalSmall } from '@shared/UI/Modals';

export const ProfileDropDown = styled(ModalSmall)`
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: #FFF;
    overflow: hidden;
    margin-top: 12px;
    z-index: 999999;
    box-shadow: 0px 0.3955px 17px 0px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    top: 44px;
    right: -15px;

    & [DropNavLink]:hover{
        background: var(--base-hover-blue-color);
        color: var(--base-blue-color);
    }

`