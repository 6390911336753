"use client"
import styled from "styled-components";
import Link from 'next/link';

export const HeaderMainStyled = styled.div`
    /* box-shadow: var(--default-style-shadow); */
    border: 1px solid #EDEDED ;    
    position: fixed;
    width: 100%;
    height: var(--header-height);
    padding: 0 20px;
    display: flex;
    /* border-radius: 0 0 16px 16px; */
    background: #FFF;
    z-index: 100;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
`
export const HeaderWrapStyled = styled.div` 
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    width: 100%; 
    max-width: 1400px;
    color: #246FFB;
    min-height: min-content;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    height: fit-content;
    /* overflow: hidden; */

    
`
export const AccontMenu = styled.div` 
    position: relative;
    display: flex;
    align-items: center;
    gap: 14px;
    cursor: pointer;
`
export const HeaderlogoSection = styled.div`
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;
     padding: 0px;
     height: 70px;
     /* width: 100%; */
     transition: all var(--anim-time) var(--anim-timing-func) var(--anim-fill-mode);
     `

export const Headerlogo = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
img {
    max-height: 100%
}`

export const HeaderNavLink = styled(Link)`
width: 100%;
    max-width: 200px;
    display: flex;
    height: 36px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #E8F1FF;  
    background:  ${props => !props.$active ? '#E8F1FF' : 'rgba(107, 159, 255, 0.85);'};
    color: ${props => !props.$active ? '#246FFB' : '#2752A1 '};
   
    transition:  all 0.2s linear;  
  
    &:hover {
    background: rgba(107, 159, 255, 0.85);

}
`

export const AccountAvatarWrap = styled.div`
  display: flex;
    align-items: center;
    gap: 10px;
    `
export const DropNavLink = styled.div` 
    display: flex;
    justify-content: flex-start;
    width: 220px;
    padding: 14px 20px;
    align-items: center;
    gap: 10px;
    color: #656565;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), rgba(58, 119, 238, 0.00);
    :hover {
        background-color: rgba(209, 225, 254, 0.30);
    }
`
